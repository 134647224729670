import { useCallback, useEffect, useRef, useState } from "react";
import { getRankedStats } from "../../api/ranked-stats";
import { ALL_ID, CurrentEvent, Event, RankedStatsId } from "../../api/ranked-stats.types";
import { RankedsTable } from "./rankeds-table/rankeds-table";
import { RankedsMaps } from "./rankeds-maps/rankeds-maps";
import { MainContainer, MainContainerMb, MainTitle, MapsContainer, MapsContainerMb, PageContainer, TableContainer } from "./rankeds.styles";
import { Box, useMediaQuery } from "@mui/material";
import { Footer } from "../../components/footer/footer";
import { screenBreackpoints } from "../../theme";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const Rankeds: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initEventId: RankedStatsId = searchParams.get('eventId') && /^[0-9]+$/.test(searchParams.get('eventId')!)
    ? searchParams.get('eventId') as RankedStatsId : ALL_ID;
  const [eventId, setEventId] = useState<RankedStatsId>(initEventId);
  const [currentEvents, setCurrentEvents] = useState<CurrentEvent[]>([]);
  const [event, setEvent] = useState<Event>();
  const isMobile = useMediaQuery(`(max-width:${screenBreackpoints.mobile})`);
  const { t } = useTranslation();

  const pageContainerRef = useRef<HTMLDivElement | null>(null);

  const handleNavigation = useCallback((eventId: RankedStatsId) => {
    setSearchParams({ eventId: eventId });
    setEventId(eventId);
    pageContainerRef.current!.scrollTo({ top: 0, behavior: 'smooth' });
  }, [setSearchParams]);

  useEffect(() => {
    const loadRankedStats = async () => {
      try {
        const rankedStats = await getRankedStats(eventId);
        if (eventId !== ALL_ID && !rankedStats.event) { handleNavigation(ALL_ID) }
        setEvent(rankedStats.event);
        setCurrentEvents(rankedStats.currentEvents);
      } catch (error) {
        window.console.log(error)
      }
    };
    loadRankedStats()
  }, [eventId, handleNavigation]);

  return (
    <PageContainer isMobile={isMobile} ref={pageContainerRef}>
      <Box>
        <MainTitle component="h1" isMobile={isMobile}>
          {t("RANKEDS_PAGE.TITLE")} <br />
          {t("RANKEDS_PAGE.SUBTITLE")}
        </MainTitle>
        {isMobile ?
          <MainContainerMb>
            {event && (
              <RankedsTable event={event} />
            )}
            {currentEvents && (
              <MapsContainerMb>
                <RankedsMaps
                  selectedMapId={eventId}
                  currentEvents={currentEvents}
                  onClickMap={(eventId) => handleNavigation(eventId)} />
              </MapsContainerMb>
            )
            }
          </MainContainerMb>
          :
          <MainContainer>
            <TableContainer>
              {event && (
                <RankedsTable event={event} />
              )}
            </TableContainer>
            <MapsContainer>
              {currentEvents && (
                <RankedsMaps
                  selectedMapId={eventId}
                  currentEvents={currentEvents}
                  onClickMap={(eventId) => handleNavigation(eventId)} />
              )}
            </MapsContainer>
          </MainContainer>
        }
      </Box>
      <Footer />
    </PageContainer>
  );
}
