import Box from "@mui/material/Box";
import { BrawlerStats, Event } from "../../../api/ranked-stats.types";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import RedditIcon from '@mui/icons-material/Reddit';
import XIcon from '@mui/icons-material/X';
import { Avatar, Icon, IconButton, TablePagination, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { CardTableContainer, TableSortLabelStyled, TableCellStyled, TableCellIconStyled, TableCellName, HintMapText, HintModeText, CardFirstSectionContainer, CardSecondSectionContainer, ShareButtonsContainer } from "./rankeds-table.styles";
import React from "react";
import { Column, Order, ShareType } from "./rankeds-table.types";
import { formatPercentaje, formatScore, getImageSrc, sortBrawlerStats } from "./rankeds-table.functions";
import { screenBreackpoints } from "../../../theme";
import { format } from "date-fns/format";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface Props {
  event: Event;
}

export const RankedsTable: React.FC<Props> = ({
  event,
}: Props) => {
  const isMobile = useMediaQuery(`(max-width:${screenBreackpoints.mobile})`);
  const rowsNumberOptions = [10, 20, 40, event.brawlersStats.length];
  const totalBattles = Math.round(event.brawlersStats.reduce((accumulator: number, brawler) => accumulator + brawler.totalBattles, 0) / 2);
  const [order, setOrder] = React.useState<Order>(Order.DESCENDING);
  const [orderBy, setOrderBy] = React.useState<keyof BrawlerStats>('effectivityRate');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(isMobile ? rowsNumberOptions[0] : rowsNumberOptions[2]);
  const { t } = useTranslation();
  const location = useLocation();
  const columns: Column[] = [
    { title: "Brawler", key: "brawlerName" },
    { title: "Wins %", key: "winRate" },
    { title: "Use %", key: "useRate" },
    { title: "Score", key: "effectivityRate", tooltip: "High win rate and low score is a situational brawler" },
  ]

  const sortColumn = (
    columnKey: keyof BrawlerStats,
  ) => {
    const newOrder = orderBy === columnKey
      ? (order === Order.ASCENDING ? Order.DESCENDING : Order.ASCENDING)
      : Order.DESCENDING;
    setOrder(newOrder);
    setOrderBy(columnKey);
  };

  const changePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShare = (shareType: ShareType) => {
    const url = window.location.origin + location.pathname + location.search;
    const title = t("RANKEDS_PAGE.SHARE.SHARE_TITLE")
      + (event.id?.toString() === undefined ? t("RANKEDS_PAGE.TABLE.ALL_MAPS") : event.name)
      + (event.id?.toString() === undefined ? "" : t("RANKEDS_PAGE.TABLE.BEST_BRAWLERS_AT") + t(`DOMINE.MODE.${event.mode}`))
      + "!";

    switch (shareType) {
      case ShareType.REDDIT:
        const redditShareUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&type=LINK`;
        window.open(redditShareUrl, '_blank');
        break;

      case ShareType.X:
        const twitterShareUrl = `https://x.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title + " @topbrawl_")}`;
        window.open(twitterShareUrl, '_blank');
        break;

      default:
        if (navigator.share) {
          navigator.share({ title: title, url: url });
        } else {
          navigator.clipboard.writeText(url)
            .then(() => {
              alert(t("RANKEDS_PAGE.SHARE.CLIPBOARD"));
            })
            .catch((error) => {
              console.error('Error copying to clipboard', error);
            });
        }
        break;
    }
  };

  const visibleStats = React.useMemo(
    () =>
      sortBrawlerStats(event.brawlersStats, orderBy, order).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, event.brawlersStats],
  );

  return (
    <Box>
      <CardTableContainer>
        <CardFirstSectionContainer isMobile={isMobile}>
          <Typography component="h2" fontSize={"1.2em"}>
            {t("RANKEDS_PAGE.TABLE.BEST_BRAWLERS")}
            {event.id?.toString() === undefined
              ? <HintMapText>{t("RANKEDS_PAGE.TABLE.ALL_MAPS")}</HintMapText>
              : <HintModeText>{t(`DOMINE.MODE.${event.mode}`)}</HintModeText>}
            <span>
              {event.id?.toString() === undefined
                ? ""
                : t("RANKEDS_PAGE.TABLE.BEST_BRAWLERS_AT")}
            </span>
            <HintMapText>
              {event.id?.toString() === undefined
                ? ""
                : event.name}
            </HintMapText>
          </Typography>
          <Typography component="span" fontSize={"1em"}>
            {t("RANKEDS_PAGE.TABLE.STATS_DATA",
              {
                totalBattles: totalBattles.toLocaleString(),
                updatedAt: format(new Date(event.lastUpdate.seconds * 1000), 'dd/MM/yyyy')
              })}
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map((column) => (
                  <TableCellStyled key={column.key} isMobile={isMobile}>
                    <TableSortLabelStyled
                      active={orderBy === column.key}
                      direction={order}
                      onClick={() => sortColumn(column.key)}
                    >
                      <Tooltip title={column.tooltip} placement="top" enterDelay={800}>
                        <Box>
                          {column.title}
                        </Box>
                      </Tooltip>
                    </TableSortLabelStyled>
                  </TableCellStyled>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleStats.map((brawlerStats) => (
                <TableRow key={brawlerStats.brawlerId}>
                  <TableCellIconStyled isMobile={isMobile}>
                    <Avatar src={getImageSrc(brawlerStats.brawlerName)} >
                      <Avatar src="/images/brawlers-icon/unknown.webp" />
                    </Avatar>
                  </TableCellIconStyled>
                  <TableCellName isMobile={isMobile}>{brawlerStats.brawlerName}</TableCellName>
                  <TableCellStyled isMobile={isMobile}>{formatPercentaje(brawlerStats.winRate)}</TableCellStyled>
                  <TableCellStyled isMobile={isMobile}>{formatPercentaje(brawlerStats.useRate)}</TableCellStyled>
                  <TableCellStyled isMobile={isMobile}>{formatScore(brawlerStats.effectivityRate)}</TableCellStyled>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={rowsNumberOptions}
            component="div"
            count={event.brawlersStats.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
          />
        </CardFirstSectionContainer>
        <CardSecondSectionContainer isMobile={isMobile}>
          <Typography justifyContent={"center"}>
            {t("RANKEDS_PAGE.SHARE.SHARE")}
            <HintMapText>
              {event.id?.toString() === undefined
                ? t("RANKEDS_PAGE.TABLE.ALL_MAPS")
                : event.name}
            </HintMapText>
            {t("RANKEDS_PAGE.SHARE.TIER_FRIENDS")}
          </Typography>
          <ShareButtonsContainer>
            <IconButton size="small"
              onClick={() => handleShare(ShareType.REDDIT)}>
              <RedditIcon />
            </IconButton>
            <IconButton size="small"
              onClick={() => handleShare(ShareType.X)}>
              <XIcon />
            </IconButton>
            <IconButton size="small"
              onClick={() => handleShare(ShareType.DEFAULT)}>
              <Icon baseClassName="material-symbols-outlined">share</Icon>
            </IconButton>
          </ShareButtonsContainer>
        </CardSecondSectionContainer>
      </CardTableContainer>
    </Box>
  );
}
