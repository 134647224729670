import { Navigate, Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "./routes.constants";
import { Rankeds } from "../pages/rankeds/rankeds";
import { AboutUs } from "../pages/about-us/about-us";
import { LanguageRouter } from "./language/language-router";
import { Languages } from "../hooks/language/language-hook.types";

export const AppRoutes: React.FC = () => {
  const languages = Object.values(Languages);

  const routes = () => (
    <>
      <Route path={APP_ROUTES.HOME} element={<Rankeds />} />
      <Route path={APP_ROUTES.RANKEDS} element={<Rankeds />} />
      <Route path={APP_ROUTES.ABOUT_US} element={<AboutUs />} />
    </>
  )

  return (
    <Routes>
      <Route element={<LanguageRouter />}>
        {routes()}
      </Route>
      {languages.map((lang) => (
        <Route key={lang} path={`/${lang}`} element={<LanguageRouter />}>
          {routes()}
          <Route path="*" element={<Navigate to={`/${lang}`} />} />
        </Route>
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
