import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Languages } from '../../../hooks/language/language-hook.types';

interface Props {
    anchor?: HTMLElement;
    onClose: (language?: Languages) => void;
}

export const LanguageMenu: React.FC<Props> = ({
    anchor,
    onClose
}) => {
    const open = Boolean(anchor);

    return (
        <Menu
            id="language-menu"
            anchorEl={anchor}
            open={open}
            onClose={() => onClose()}
        >
            <MenuItem onClick={() => onClose(Languages.English)}>English</MenuItem>
            <MenuItem onClick={() => onClose(Languages.Spanish)}>Español</MenuItem>
        </Menu>
    );
}